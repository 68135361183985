import React, { useState } from 'react';
import './assets/footer.css';
import './assets/code.css';

const Footer = () => {
  const [code, setCode] = useState('');
  const [message, setMessage] = useState('');
  const [showInput, setShowInput] = useState(false);

  const correctCode = '4125'; 

  const handleSubmit = (e) => {
    e.preventDefault();
    if (code === correctCode) {

      window.open('https://wa.me/message/VOBEHY7HQSK3G1'); 
    } else {
      setMessage('الكود المدخل غير صحيح. حاول مرة أخرى.');
    }
  };

  return (
    <footer className="footer">
      <div className="social-icons">
        <a href="https://github.com/RWELYY/a"><i className="bx bxl-github"></i></a>
        <a href="https://instagram.com/rwelyy/"><i className="bx bxl-instagram-alt"></i></a>
        <a href="https://discord.com/channels/@me/470306150592675841"><i className="bx bxl-discord"></i></a> 
      </div>
      <ul className="list">
        <li><a href="#home">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#contact">Contact</a></li>
        <li onClick={() => setShowInput(!showInput)} style={{ cursor: 'pointer', color: 'blue' }}>
          <span style={{ color: 'white' }}>WhatsApp</span>
        </li>
      </ul>
      {showInput && (
        <form onSubmit={handleSubmit} className="code-form">
          <input
            type="text"
            placeholder="Enter Secret Code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
          <button type="submit">Submit</button>
          {message && <p>{message}</p>}
        </form>
      )}
      <p className="copyright">© Rwélyy</p>
    </footer>
  );
};

export default Footer;
