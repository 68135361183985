import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; 
import config from "./config"; 
import "./css/login.css";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    // استخدام البيانات من config.js
    if (username === config.adminUsername && password === config.adminPassword) {
      localStorage.setItem("isLoggedIn", "true"); 
      navigate("/dashboard"); 
    } else {
      setError("Invalid username or password!"); 
    }
  };

  return (
    <div className="login-container">
      <section className="side"></section>
      <section className="main">
        <div className="login-box">
          <p className="title">Welcome back</p>
          <div className="separator"></div>
          <p className="welcome-message">
            Please, provide login credentials to proceed and have access to all our services
          </p>

          <form className="login-form" onSubmit={handleSubmit}>
            <div className="form-control">
              <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <i className="fas fa-user"></i>
            </div>
            <div className="form-control">
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <i className="fas fa-lock"></i>
            </div>
            {error && <p className="error-message">{error}</p>}
            <button type="submit" className="submit">Login</button>
          </form>
        </div>
      </section>
    </div>
  );
};

export default Login;
